@mixin loading-mask {
  .loading-mask {
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: #050b0c;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 9999;
    opacity: 0.8;
    &.fixed {
      position: fixed;
    }
    &:before {
      content: '';
      background-color: rgba(0, 0, 0, 0);
      border: 5px solid #f0322c;
      opacity: 0.9;
      border-right: 5px solid rgba(0, 0, 0, 0);
      border-left: 5px solid rgba(0, 0, 0, 0);
      border-radius: 50px;
      box-shadow: 0 0 35px #f0322c;
      width: 100px;
      height: 100px;
      -moz-animation: spinPulse 1s infinite ease-in-out;
      -webkit-animation: spinPulse 1s infinite linear;
      position: absolute;
      top: calc(50% - 45px);
      left: calc(50% - 45px);
    }
    &:after {
      content: '';
      background-color: rgba(0, 0, 0, 0);
      border: 5px solid #f0322c;
      opacity: 0.9;
      border-left: 5px solid rgba(0, 0, 0, 0);
      border-right: 5px solid rgba(0, 0, 0, 0);
      border-radius: 50px;
      box-shadow: 0 0 15px #f0322c;
      width: 70px;
      height: 70px;
      -moz-animation: spinoffPulse 1s infinite linear;
      -webkit-animation: spinoffPulse 1s infinite linear;
      position: absolute;
      top: calc(50% - 30px);
      left: calc(50% - 30px);
    }
  }

  @-moz-keyframes spinPulse {
    0% {
      -moz-transform: rotate(160deg);
      opacity: 0;
      box-shadow: 0 0 1px #f0322c;
    }
    50% {
      -moz-transform: rotate(145deg);
      opacity: 1;
    }
    100% {
      -moz-transform: rotate(-320deg);
      opacity: 0;
    }
  }
  @-moz-keyframes spinoffPulse {
    0% {
      -moz-transform: rotate(0deg);
    }
    100% {
      -moz-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spinPulse {
    0% {
      -webkit-transform: rotate(160deg);
      opacity: 0;
      box-shadow: 0 0 1px #f0322c;
    }
    50% {
      -webkit-transform: rotate(145deg);
      opacity: 1;
    }
    100% {
      -webkit-transform: rotate(-320deg);
      opacity: 0;
    }
  }
  @-webkit-keyframes spinoffPulse {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
}
