@mixin travel-destination-clubs {
  .ng-select {
    position: relative;
  }
  .ng-select.ng-select-opened.ng-select-clearable
    .ng-select-container.ng-has-value:hover
    .ng-clear-wrapper {
    opacity: 0;
    pointer-events: none;
  }
  .ng-select.ng-select-opened.ng-select-clearable
    .ng-select-container.ng-has-value:hover
    .ng-arrow-wrapper {
    opacity: 1;
    pointer-events: auto;
  }
  .ng-select.ng-select-opened > .ng-select-container {
    background: #fff;
    border-color: #40a9ff;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
  .ng-select.ng-select-opened > .ng-select-container .ng-arrow-wrapper {
    transform: rotate(180deg);
    opacity: 1;
  }
  [dir='rtl']
    .ng-select.ng-select-opened
    > .ng-select-container
    .ng-arrow-wrapper {
    transform: rotate(-180deg);
  }
  .ng-select.ng-select-focused .ng-select-container {
    border-color: #40a9ff;
  }
  .ng-select.ng-select-disabled > .ng-select-container {
    background-color: #f5f5f5;
  }
  .ng-select.ng-select-disabled > .ng-select-container:hover {
    border: 1px solid #d9d9d9;
    cursor: not-allowed;
  }
  .ng-select.ng-select-disabled
    > .ng-select-container
    .ng-value-container
    .ng-value {
    color: rgba(0, 0, 0, 0.25);
  }
  .ng-select .ng-has-value .ng-placeholder {
    display: none;
  }
  .ng-select.ng-select-clearable
    .ng-select-container.ng-has-value:hover
    .ng-clear-wrapper {
    opacity: 1;
    display: none;
  }
  .ng-select.ng-select-clearable
    .ng-select-container.ng-has-value:hover
    .ng-arrow-wrapper {
    opacity: 0;
    pointer-events: none;
  }
  .ng-select .ng-select-container {
    color: rgba(0, 0, 0, 0.65);
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    min-height: 30px;
    align-items: center;
    transition: all 0.3s;
    box-sizing: border-box;
    padding-right: 11px;
    padding-left: 11px;
  }
  .ng-select .ng-select-container .ng-input {
    line-height: 22px;
  }
  .ng-select .ng-select-container .ng-input input {
    color: rgba(0, 0, 0, 0.65);
  }
  .ng-select .ng-select-container .ng-value-container {
    align-items: center;
    margin-top: 15px;
  }
  .ng-select .ng-select-container .ng-value-container .ng-value {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65);
  }
  .ng-select .ng-select-container .ng-value-container .ng-placeholder {
    font-size: 14px;
    color: rgba(153, 153, 153, 0.65);
  }
  .ng-select.ng-select-single.ng-select-opened .ng-select-container .ng-value {
    opacity: 0.4;
  }
  .ng-select.ng-select-single
    .ng-select-container
    .ng-value-container
    .ng-input {
    top: 2px;
    left: 0;
    padding-left: 10px;
    padding-right: 50px;
  }
  [dir='rtl']
    .ng-select.ng-select-single
    .ng-select-container
    .ng-value-container
    .ng-input {
    padding-right: 10px;
    padding-left: 50px;
  }
  .ng-select.ng-select-multiple.ng-select-disabled
    > .ng-select-container
    .ng-value-container
    .ng-value {
    background-color: #f5f5f5;
  }
  .ng-select.ng-select-multiple.ng-select-disabled
    > .ng-select-container
    .ng-value-container
    .ng-value
    .ng-value-label {
    padding: 0 5px;
  }
  .ng-select.ng-select-multiple .ng-select-container {
    min-height: 58px;
    padding-left: 5px;
  }
  [dir='rtl'] .ng-select.ng-select-multiple .ng-select-container {
    padding-right: 5px;
    padding-left: 11px;
  }
  [dir='rtl']
    .ng-select.ng-select-multiple
    .ng-select-container
    .ng-value-container
    .ng-placeholder {
    margin-left: 0;
    margin-right: 6px;
  }
  .ng-select.ng-select-multiple
    .ng-select-container
    .ng-value-container
    .ng-value {
    margin-top: 3px;
    margin-right: 4px;
    background-color: #e5ecff;
    border: 1px solid #2e486a;
    border-radius: 4px;
    color: #2e486a;
    display: flex;
  }
  [dir='rtl']
    .ng-select.ng-select-multiple
    .ng-select-container
    .ng-value-container
    .ng-value {
    margin-right: 0;
    margin-left: 4px;
  }
  .ng-select.ng-select-multiple
    .ng-select-container
    .ng-value-container
    .ng-value.ng-value-disabled {
    background-color: #f5f5f5;
    color: rgba(0, 0, 0, 0.25);
  }
  .ng-select.ng-select-multiple
    .ng-select-container
    .ng-value-container
    .ng-value.ng-value-disabled
    .ng-value-label {
    padding-left: 5px;
  }
  [dir='rtl']
    .ng-select.ng-select-multiple
    .ng-select-container
    .ng-value-container
    .ng-value.ng-value-disabled
    .ng-value-label {
    padding-left: 0;
    padding-right: 5px;
  }
  .ng-select.ng-select-multiple
    .ng-select-container
    .ng-value-container
    .ng-value
    .ng-value-label {
    order: 1;
    display: inline-block;
    padding: 0 5px;
  }
  .ng-select.ng-select-multiple
    .ng-select-container
    .ng-value-container
    .ng-value
    .ng-value-icon {
    order: 2;
    height: 17px;
    font-size: 0;
  }
  .ng-value-icon::after {
    content: '\f057'; /* Unicode for the 'times-circle' icon */
    font-family: 'Font Awesome 5 Free'; /* Adjust based on FA version */
    font-weight: 400; /* Needed for solid icons */
    font-size: 15px; /* Adjust based on your needs */
    margin-right: 4px;
    display: block; /* Make the pseudo-element behave like a block */
    color: #2e486a;
  }
  .ng-select.ng-select-multiple
    .ng-select-container
    .ng-value-container
    .ng-placeholder {
    top: 50%;
    height: 20px;
    margin-top: -10px;
    margin-left: 6px;
  }
  .ng-select.ng-select-multiple
    .ng-select-container
    .ng-value-container
    .ng-input {
    height: 24px;
    margin-top: 3px;
  }
  .ng-select .ng-clear-wrapper {
    opacity: 0;
    color: #a6a6a6;
    background: rgba(0, 0, 0, 0.25);
    width: 12px;
    height: 12px;
    text-align: center;
    border-radius: 12px;
    vertical-align: middle;
    transition: color 0.3s ease;
    position: absolute;
    right: 13px;
    display: none;
  }
  [dir='rtl'] .ng-select .ng-clear-wrapper {
    left: 13px;
    right: auto;
  }
  .ng-select .ng-clear-wrapper:hover {
    background: rgba(0, 0, 0, 0.45);
  }
  .ng-select .ng-clear-wrapper:focus {
    background: rgba(0, 0, 0, 0.45);
    outline: none;
  }
  .ng-select .ng-clear-wrapper .ng-clear {
    color: #fff;
    font-size: 9px;
    position: absolute;
    left: 3px;
    line-height: 12px;
  }
  .ng-select .ng-spinner-zone {
    padding: 5px 5px 0 0;
  }
  [dir='rtl'] .ng-select .ng-spinner-zone {
    padding: 5px 0 0 5px;
  }
  .ng-select .ng-arrow-wrapper {
    width: 16px;
    height: 26px;
    transition: transform 0.3s;
    display: none;
  }
  .ng-select .ng-arrow-wrapper .ng-arrow {
    border-style: solid;
    border-width: 0.1rem 0.1rem 0 0;
    height: 8px;
    width: 8px;
    top: -2px;
    transform: rotate(135deg);
    color: rgba(0, 0, 0, 0.25);
  }
  .ng-dropdown-panel {
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    margin-top: 4px;
    margin-bottom: 4px;
    left: 0;
  }
  .ng-dropdown-panel.ng-select-top {
    bottom: 100%;
    border-bottom-color: #f3f3f3;
  }
  .ng-dropdown-panel.ng-select-right {
    left: 100%;
    top: 0;
    border-bottom-color: #f3f3f3;
    margin-top: 0;
    margin-left: 4px;
  }
  .ng-dropdown-panel.ng-select-bottom {
    top: 100%;
    border-top-color: #f3f3f3;
  }
  .ng-dropdown-panel.ng-select-left {
    left: calc(-100% - 4px);
    top: 0;
    border-bottom-color: #f3f3f3;
    margin-top: 0;
  }
  .ng-dropdown-panel .ng-dropdown-header {
    border-bottom: 1px solid #d9d9d9;
    padding: 5px 7px;
  }
  .ng-dropdown-panel .ng-dropdown-footer {
    border-top: 1px solid #d9d9d9;
    padding: 5px 7px;
  }
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup {
    user-select: none;
    padding: 4px 12px;
    color: rgba(0, 0, 0, 0.45);
    cursor: pointer;
  }
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-disabled {
    cursor: default;
    color: rgba(0, 0, 0, 0.45);
  }
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-marked {
    background-color: #e6f7ff;
  }
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected {
    background-color: #fafafa;
    font-weight: 600;
  }
  .ng-dropdown-panel
    .ng-dropdown-panel-items
    .ng-optgroup.ng-option-selected.ng-option-marked {
    background-color: #e6f7ff;
  }
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.65);
    padding: 5px 12px;
    transition: background 0.3s ease;
  }
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option:first-child {
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
  }
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option:last-child {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
    background-color: #fafafa;
    font-weight: 600;
  }
  .ng-dropdown-panel
    .ng-dropdown-panel-items
    .ng-option.ng-option-selected.ng-option-marked {
    color: rgba(0, 0, 0, 0.65);
    background-color: #e6f7ff;
  }
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
    background-color: #e6f7ff;
    color: rgba(0, 0, 0, 0.65);
  }
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child {
    padding-left: 20px;
  }
  [dir='rtl']
    .ng-dropdown-panel
    .ng-dropdown-panel-items
    .ng-option.ng-option-child {
    padding-right: 20px;
    padding-left: 0;
  }
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-option-label {
    font-size: 14px;
  }
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label {
    font-size: 80%;
    font-weight: 400;
    padding-right: 5px;
  }
  [dir='rtl']
    .ng-dropdown-panel
    .ng-dropdown-panel-items
    .ng-option
    .ng-tag-label {
    padding-left: 5px;
    padding-right: 0;
  }
  [dir='rtl'] .ng-dropdown-panel {
    direction: rtl;
    text-align: right;
  }

  .ng-select-floating-container {
    position: relative;
    display: inline-block;
  }

  .ng-select-floating-label {
    width: 95%;
    padding: 0 !important;
    top: 30% !important;
    left: 10px !important;
    color: #aaa;
    pointer-events: none;
    transition: 0.2s ease all !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 15px;
  }

  .ng-select-floating-label.active {
    z-index: 1001;
    left: 10px;
    font-size: 12px;
    transform: translateY(-30%);
    transition: 0.2s ease all !important;
  }

  .ng-select {
    display: block;
  }
}
